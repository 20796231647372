import { Injectable } from '@angular/core';
import moment from 'moment';

import { DataService } from './data.service';
import { HolidaysHttpRequestService } from '../../entities/holidays/services/holidays-http-request.service';
import { GermanStateService } from './german-state.service';
import { Employee } from '../../entities/employees/models/employee.model';
import { TimeEntry } from '../../entities/time-entries/models/time-entry.model';
import { TargetWorkingHours } from '../../entities/target-working-hourses/models/target-working-hours.model';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
	public publicHolidays: any = {};

	public selectedDate: Date = new Date();

	public currentEmployee: Employee;
	public timeEntries: TimeEntry[] = [];
	public currentTargetWorkingHoursForEmployee: TargetWorkingHours;

	public targetWorkingHours: number = 0;

	public recordedHours: number = 0;
	public recordedHoursForTimeSpan: number = 0;
	public monthlyGoal: number = 0;
	public leftoverHours: number = 0;
	public calculatedOvertime: number = 0;
	public userOvertime: number = 0;

  public constructor(
		private readonly germanStateService: GermanStateService,
		private readonly dataService: DataService,
		private readonly holidaysHttpRequestService: HolidaysHttpRequestService
	) {
		this.holidaysHttpRequestService.findPublicHolidays().subscribe((holidays) => {
			this.publicHolidays = holidays;
			this.calculateTargetWorkingHours(this.currentEmployee);
		});
  }

	public loadTimeEntries(): void {
		this.timeEntries = this.dataService.timeEntriesCacheService.timeentries.filter((timeEntry: TimeEntry) => timeEntry?.employee?.id === this.currentEmployee?.id);
	}

	public calculateTargetWorkingHours(employee: Employee): void {
		if (!employee) {
			return;
		}

		const targetWorkingHoursObj = employee.targetWorkingHourses ? employee.targetWorkingHourses.find(obj => {
			const fromDate = new Date(obj.from);
			const toDate = new Date(obj.to);
			return this.selectedDate >= fromDate && this.selectedDate <= toDate;
		}) : {} as any;

		if (targetWorkingHoursObj) {
			this.currentTargetWorkingHoursForEmployee = targetWorkingHoursObj;
			console.log('Target Working Hours Object', targetWorkingHoursObj);

			// Calculate the number of each weekday in the month of this.selectedDate
			const year = this.selectedDate.getFullYear();
			const month = this.selectedDate.getMonth();
			const daysInMonth = new Date(year, month + 1, 0).getDate();
			const weekdaysCount = { monday: 0, tuesday: 0, wednesday: 0, thursday: 0, friday: 0, saturday: 0, sunday: 0 };

			const startDate = new Date(employee.startDate);
			const isSameMonthAndYear = startDate.getFullYear() === year && startDate.getMonth() === month;

			for (let day = 1; day <= daysInMonth; day++) {
				// Skip days before the start date if it's in the same month and year
				if (isSameMonthAndYear && day < startDate.getDate()) {
					continue;
				}

				const weekday = new Date(year, month, day).getDay();
				if (weekday === 0) weekdaysCount.sunday++;
				else if (weekday === 1) weekdaysCount.monday++;
				else if (weekday === 2) weekdaysCount.tuesday++;
				else if (weekday === 3) weekdaysCount.wednesday++;
				else if (weekday === 4) weekdaysCount.thursday++;
				else if (weekday === 5) weekdaysCount.friday++;
				else if (weekday === 6) weekdaysCount.saturday++;
			}

			// Multiply the number of each weekday by the respective hours from the found object
			this.targetWorkingHours = weekdaysCount.monday * Number(targetWorkingHoursObj.monday)
				+ weekdaysCount.tuesday * Number(targetWorkingHoursObj.tuesday)
				+ weekdaysCount.wednesday * Number(targetWorkingHoursObj.wednesday)
				+ weekdaysCount.thursday * Number(targetWorkingHoursObj.thursday)
				+ weekdaysCount.friday * Number(targetWorkingHoursObj.friday)
				+ weekdaysCount.saturday * Number(targetWorkingHoursObj.saturday)
				+ weekdaysCount.sunday * Number(targetWorkingHoursObj.sunday);

			const stateToApply = employee.stateToApply;
			if (stateToApply) {
				const stateShortForm = this.germanStateService.getShortForm(stateToApply);
				if (stateShortForm && this.publicHolidays[stateShortForm]) {
					// Filter the holidays that fall within the selected month
					const selectedMonthHolidays = Object.entries(this.publicHolidays[stateShortForm]).filter(([date, _]) => {
						const holidayMonth = new Date(date).getMonth();
						return holidayMonth === this.selectedDate.getMonth();
					});
					const stateHolidayCount = selectedMonthHolidays.length;

					// Filter the absences that fall within the selected month
					const selectedMonthAbsences = employee.abscences ? employee.abscences.filter(absence => {
						const absenceMonth = new Date(absence.from).getMonth();
						return absenceMonth === this.selectedDate.getMonth();
					}) : [];
					const absenceCount = selectedMonthAbsences.length;

					// Deduct the count of holidays and absences from this.targetWorkingHours
					this.targetWorkingHours -= (absenceCount * 8) + (stateHolidayCount * 8);
				}
			}
			console.log('Target Working Hours', this.targetWorkingHours);
		} else {
			this.targetWorkingHours = 0;
		}
	}

  public calculateValues(entries: TimeEntry[]): void {
		const timeEntries: TimeEntry[] = entries || [];

		const a = timeEntries.filter(entry => {
			const entryDate = moment(entry.date);
			return entryDate.month() === this.selectedDate.getMonth() && entryDate.year() === this.selectedDate.getFullYear();
		})

		const from = this.currentTargetWorkingHoursForEmployee?.from;
		const to = this.currentTargetWorkingHoursForEmployee?.to;
		const b = timeEntries.filter(entry => {
			const entryDate = moment(entry.date);
			return entryDate.isBetween(from, to, 'days', '[]');
		})

		this.recordedHours = this.calculateRecordedHours(a);
		this.recordedHoursForTimeSpan = this.calculateRecordedHours(b);

		this.monthlyGoal = this.targetWorkingHours;
		this.leftoverHours = this.targetWorkingHours - this.recordedHours;
		this.calculatedOvertime =  this.recordedHoursForTimeSpan - this.targetWorkingHours;

		this.userOvertime = this.calculatedOvertime;
	}

	public calculateRecordedHours(entries: TimeEntry[]): number {
		return entries.reduce((totalHours, entry) => totalHours + this.calculateHoursWorked(entry), 0);
	}

	public calculateHoursWorked(entry: TimeEntry): number {
		const from = this.createDateFromTime(entry.from);
		const to = this.createDateFromTime(entry.to);
		const pause = entry.pause ? this.createDateFromTime(entry.pause) : this.createDateFromTime('00:00:00');
		
		const pauseInHours = pause.getHours() + pause.getMinutes() / 60;
		const hoursWorked = moment(to).diff(from, 'hours', true) - pauseInHours;
		
		return hoursWorked;
	}

	public createDateFromTime(time: string): Date {
		const [hours, minutes, seconds] = time.split(':').map(Number);
		return new Date(0, 0, 0, hours, minutes, seconds);
	}

	public isTimeEntryOverlapping(fromValue: Date, toValue: Date): boolean {
		return this.timeEntries.some((existingEntry: TimeEntry) => {
			const existingStart = moment(existingEntry.from);
			const existingEnd = moment(existingEntry.to);
			const newStart = moment(fromValue).set({
				month: existingStart.month(),
				date: existingStart.date(),
				year: existingStart.year()
			});
			const newEnd = moment(toValue).set({
				month: existingEnd.month(),
				date: existingEnd.date(),
				year: existingEnd.year()
			});

			// Check if the new entry starts or ends during the existing entry
			const startsDuringExisting = newStart.isBetween(existingStart, existingEnd, "hours", '[)');
			const endsDuringExisting = newEnd.isBetween(existingStart, existingEnd, "hours", '[)');

			// Check if the new entry overlaps the existing entry
			const overlapsExisting = startsDuringExisting || endsDuringExisting;

			return overlapsExisting;
		});
	}
}
