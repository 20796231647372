<div class="container">
	<div class="legend">
		<div class="legend-item">
			<div class="legend-color" style="background-color: green;"></div>
			<span>Bezahlter Urlaub</span>
		</div>
		<div class="legend-item">
			<div class="legend-color" style="background-color: red;"></div>
			<span>Krankheit</span>
		</div>
		<div class="legend-item">
			<div class="legend-color" style="background-color: #7F7F7F;"></div>
			<span>Gleitzeit</span>
		</div>
		<div class="legend-item">
			<div class="legend-color" style="background-color: #FCC419;"></div>
			<span>Elternzeit</span>
		</div>
	</div>

	<!-- Desktop Table -->
	<table class="desktop-table">
		<thead>
			<tr>
				<th>Name</th>
				<th *ngFor="let month of months">{{ month }}</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let employee of employees; let i = index; trackBy: trackByEmployee">
				<ng-container *ngIf="i === 0 || employee.outlet !== employees[i - 1].outlet">
					<tr>
						<td [attr.colspan]="months.length + 1" class="outlet-row">{{ employee.outlet }}</td>
					</tr>
				</ng-container>
				<tr>
					<td>{{ employee.firstname + ' ' + employee.lastname }}</td>
					<td *ngFor="let month of months; let j = index; trackBy: trackByMonth" class="month">
						<table class="inner-table">
							<tr>
								<td *ngFor="let day of daysPerMonth.get(month)!; trackBy: trackByDay" [ngClass]="getCellClassFromField(employee, j, day)" (click)="onClickCell(employee, j, day)">
									<ejs-tooltip [content]="getTooltipFromField(employee, j, day)" position="TopCenter" openDelay="100">
										<span class="tooltip-target">
											<!-- Tooltip content -->
										</span>
									</ejs-tooltip>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</div>

<ejs-dialog
	id="dialog"
	#ejDialog
	header=""
	[showCloseIcon]="true"
	[visible]="isLeaveDescVisible && selectedLeave.type"
	isModal="false"
	(beforeClose)="onDialogClose()"
	width="415px"
	>
	<div>
		<h2 *ngIf="selectedLeave.type" class="mt-0">{{selectedLeave?.type}}</h2>
		<div class="dialog-content">
			<div class="row-wrapper">
				<div class="icons">
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M212.31-100Q182-100 161-121q-21-21-21-51.31v-535.38Q140-738 161-759q21-21 51.31-21h55.38v-84.61h61.54V-780h303.08v-84.61h60V-780h55.38Q778-780 799-759q21 21 21 51.31v535.38Q820-142 799-121q-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-375.38H200v375.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM200-607.69h560v-100q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v100Zm0 0V-720v112.31Zm280 210.77q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35Zm-160 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35Zm320 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35ZM480-240q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q494.69-240 480-240Zm-160 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q334.69-240 320-240Zm320 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q654.69-240 640-240Z"/></svg>
				</div>
				<div>
					{{selectedLeave.from | date: 'dd. MMMM yyyy (HH:mm)'}} - {{selectedLeave.to | date: 'dd. MMMM yyyy (HH:mm)'}}
				</div>
			</div>
			<div class="row-wrapper">
				<div class="icons">
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M330-250h300v-60H330v60Zm0-160h300v-60H330v60Zm-77.69 310Q222-100 201-121q-21-21-21-51.31v-615.38Q180-818 201-839q21-21 51.31-21H570l210 210v477.69Q780-142 759-121q-21 21-51.31 21H252.31ZM540-620v-180H252.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v615.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h455.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-620H540ZM240-800v180-180V-160v-640Z"/></svg>
				</div>
				<div>
					<span *ngIf="selectedLeave.substitution">
						Vertretung: {{getSubstitutionName(selectedLeave.substitution)}}
						<br>
					</span>
					Kommentar: {{selectedLeave.comment}}
				</div>
			</div>
		</div>
	</div>
</ejs-dialog>
